var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-bg pt-4 pb-4"
  }, [_c('div', {
    staticClass: "account-pages"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-6 text-right"
  }, [_c('div', {
    staticClass: "login-left-side pt-3"
  }, [_c('h2', {
    staticClass: "mt-2",
    style: {
      color: '#0b4990'
    }
  }, [_vm._v(" One App for all your needs! ")])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('h3', {
    staticClass: "mt-4 pb-4",
    style: {
      color: '#0b4990'
    }
  }, [_vm._v(" Enjoy your Shopping with us! ")]), _c('div', {
    staticClass: "card mt-4 rounded-lg"
  }, [_c('div', {
    staticClass: "card-header pt-2 pb-2 text-center bg-black rounded-top"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('b-img', {
    attrs: {
      "src": "/img/login/logo_icon.png",
      "width": "55px",
      "height": "55px",
      "fluid": ""
    }
  })], 1), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('b-img', {
    staticClass: "mt-2",
    attrs: {
      "src": "/img/login/text logo.png",
      "width": "150px",
      "height": "auto"
    }
  })], 1)])]), _c('div', {
    staticClass: "card-body p-4"
  }, [_c('h3', {
    staticClass: "text-center mb-4"
  }, [_vm._v("Change Password")]), _c('form', {
    staticClass: "theme-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('v-text-field', {
    attrs: {
      "id": "password",
      "type": "password",
      "placeholder": "Enter New Password",
      "required": ""
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "id": "otp",
      "placeholder": "Enter OTP",
      "required": ""
    },
    model: {
      value: _vm.otp,
      callback: function callback($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('div', {
    staticClass: "pt-4 form-group text-center"
  }, [!_vm.isLoading ? [_c('b-button', {
    staticClass: "btn-primary",
    attrs: {
      "size": "lg",
      "type": "submit"
    }
  }, [_vm._v("Submit")])] : _vm._e(), _vm.isLoading ? _vm._l(3, function (i) {
    return _c('b-spinner', {
      key: i,
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "label": "Small Spinner",
        "type": "grow"
      }
    });
  }) : _vm._e()], 2)]), _c('p', {
    staticClass: "pt-2",
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v(" By continuing, you agree to Teka "), _c('router-link', {
    attrs: {
      "to": "/terms-and-conditions"
    }
  }, [_vm._v("Terms & Conditions")]), _vm._v(" of Use and "), _c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v(" Privacy Policy")])], 1)])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }