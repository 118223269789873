<template>
  <div class="login-bg pt-4 pb-4">
    <div class="account-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-right">
            <div class="login-left-side pt-3">
              <h2 class="mt-2" v-bind:style="{ color: '#0b4990' }">
                One App for all your needs!
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <h3 class="mt-4 pb-4" v-bind:style="{ color: '#0b4990' }">
              Enjoy your Shopping with us!
            </h3>
            <div class="card mt-4 rounded-lg">
              <div
                class="card-header pt-2 pb-2 text-center bg-black rounded-top"
              >
                <div class="row">
                  <div class="col-lg-3">
                    <b-img
                      src="/img/login/logo_icon.png"
                      width="55px"
                      height="55px"
                      fluid
                    ></b-img>
                    <!-- <img src="/img/logo_icon.png"/> -->
                  </div>
                  <div class="col-lg-7">
                    <b-img
                      src="/img/login/text logo.png"
                      width="150px"
                      height="auto"
                      class="mt-2"
                    ></b-img>
                    <!-- <img src="/img/e-পরিষেবা_text logo.png"/> -->
                  </div>
                </div>
                <!-- <span class="text-white font-weight-bold h2">E parisheva</span> -->
              </div>
              <div class="card-body p-4">
                <h3 class="text-center mb-4">Change Password</h3>
                <form class="theme-form" @submit.prevent="onSubmit">
                  <div class="form-group">
                    <v-text-field
                      v-model="password"
                      id="password"
                      type="password"
                      placeholder="Enter New Password"
                      required
                    ></v-text-field>
                    <v-text-field
                      type="number"
                      id="otp"
                      v-model="otp"
                      placeholder="Enter OTP"
                      required
                    ></v-text-field>
                  </div>

                  <div class="pt-4 form-group text-center">
                    <template v-if="!isLoading">
                      <b-button
                        class="btn-primary"
                        size="lg"
                        type="submit"
                        >Submit</b-button
                      >
                    </template>
                    <template v-if="isLoading">
                      <b-spinner
                        v-for="i in 3"
                        :key="i"
                        class="mr-1"
                        small
                        label="Small Spinner"
                        type="grow"
                      ></b-spinner>
                    </template>
                  </div>
                </form>
                <p class="pt-2" style="font-size: 13px">
                By continuing, you agree to Teka
                <router-link to="/terms-and-conditions"
                  >Terms & Conditions</router-link
                >
                of Use and
                <router-link to="/privacy-policy"> Privacy Policy</router-link>
              </p>
              </div>
              
            </div>
            <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { resetPassword } from "../auth.services";
import {} from "firebase/auth";
export default {
  name: "newPassword",
  components: {
  },
  computed: {
    isPhoneValid() {
      if (this.phone == "") return true;
      var isPhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (isPhone.test(this.phone)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      password: null,
      otp: null,
      resetRef: null,
    };
  },

  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const payload = {
          password: this.password,
          resetRef: this.$route.params.resetRefId,
          verificationCode: this.otp
        };
        console.log(payload, "resetRef");
        const result = await resetPassword(payload);
        console.log(result);
        this.$swal({
          group: "item",
          title: "Well Done !",
          text: "Password has been successfully updated",
          type: "success",
          timer: 1200,
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
      } catch (err) {
        this.$swal({
          group: "item",
          title: "Please Try Again ^_^",
          text: err.message.message,
          type: "error",
          timer: 1200,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
a {
  color: #007bff !important;
  cursor: pointer;
}

form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
}
.login-bg {
  background-image: url("/img/login/login_bg_image.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
}
.modal-title-otp a:hover {
  text-decoration: underline !important;
}
.btn-primary {
  padding: 0.5rem 8rem;
}
.is-valid-phone {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 9px;
  color: red;
}
.border {
  border: 1px solid #000;
}
.modal-title-otp {
  color: #579a70;
  cursor: pointer;
}
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  width: 65%;
}
</style>
